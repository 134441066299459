@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;700&display=swap");
@import url("https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css");
:root {
  --brand-white: #ffffff;
  --brand-light-bg: #F4F6FC;
  --brand-black: #000000;
  --brand-gray: #B5B5B5;
  --brand-gray1: #BCBCBC;
  --brand-red: #BC1823;
  --brand-red1: #DB2C37;
  --brand-green1: #157E38;
}

html,
body {
  font-family: "Rubik", Arial, Helvetica, sans-serif;
}

html body {
  background: var(--brand-light-bg);
}

* {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.mb-60 {
  margin-bottom: 60px !important;
}

.header-block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-bottom: 44px;
}

@media (max-width: 991.98px) {
  .header-block {
    margin-bottom: 24px;
  }
}

@media (max-width: 767.98px) {
  .header-block .header-logo-1 {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .header-block .header-logo-1 img {
    width: 70px;
  }
}

.header-block .header-text {
  color: var(--brand-black);
  font-size: 41px;
  font-weight: 700;
  line-height: 1.17;
  text-align: center;
}

@media (max-width: 991.98px) {
  .header-block .header-text {
    font-size: 32px;
  }
}

@media (max-width: 767.98px) {
  .header-block .header-text {
    font-size: 28px;
    -webkit-box-flex: 1;
        -ms-flex: 1 0 auto;
            flex: 1 0 auto;
  }
}

.header-block .header-logo-2 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 20px;
          column-gap: 20px;
}

@media (max-width: 767.98px) {
  .header-block .header-logo-2 {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .header-block .header-logo-2 img {
    width: 70px;
  }
}

.table-container {
  max-width: 800px;
  margin: auto;
  padding: 32px 0px 0;
}

.table-container.table-data-sm {
  padding: 0;
}

.table-container.table-data-sm h3 {
  font-size: 24px;
  line-height: 29px;
  text-align: center;
}

.table-container.table-data-sm p {
  font-size: 18px;
  line-height: 22px;
}

.table-container.table-data-sm p.price {
  font-size: 20px;
  line-height: 24px;
}

.table-container.table-data-sm p.price span {
  top: -6px;
}

.table-container.table-data-sm .brick-rates {
  display: block;
}

.table-container.table-data-sm .brick-sizes {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.table-container.table-data-sm .brick-type {
  background: var(--brand-white);
  border-radius: 10px;
  padding-left: 34px;
  padding-right: 34px;
}

.table-container.table-data-sm .brick-size {
  background: none;
  margin-bottom: 0;
  border-radius: 0px;
}

.table-container h3 {
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
  text-transform: uppercase;
  margin: 0 0 18px;
}

@media (max-width: 767.98px) {
  .table-container h3.with-height {
    min-height: 50px;
  }
}

.table-container .brick-rates {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.table-container .brick-type {
  padding: 0px;
  text-align: center;
  border-radius: 10px;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

.table-container .brick-type:first-child .brick-sizes .brick-size {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.table-container .brick-type:last-child .brick-sizes .brick-size {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.table-container .brick-sizes {
  margin-top: 10px;
}

.table-container .brick-size {
  margin-bottom: 10px;
  background: var(--brand-white);
  padding: 17px 0 9px 0;
}

.table-container .brick-size.alternative {
  min-height: 132px;
  padding-top: 51px;
  padding-bottom: 0px;
}

@media (max-width: 767.98px) {
  .table-container .brick-size.alternative {
    padding-top: 46px;
  }
}

.table-container .brick-size.alternative .interlock {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.table-container .brick-size.alternative .interlock p {
  font-size: 24px;
  line-height: 36px;
  margin-bottom: 0px;
}

@media (max-width: 767.98px) {
  .table-container .brick-size.alternative .interlock p {
    font-size: 16px;
    line-height: 23.4px;
  }
}

@media (max-width: 767.98px) {
  .table-container .brick-size.alternative {
    min-height: 102px;
  }
}

.table-container p {
  font-size: 20px;
  line-height: 1.2;
  color: var(--brand-black);
  text-transform: uppercase;
  margin: 0 0 10px;
}

@media (max-width: 767.98px) {
  .table-container p {
    font-size: 16px;
  }
}

.table-container p.price {
  color: var(--brand-red1);
  font-size: 30px;
  line-height: 1.2;
  margin-bottom: 0;
}

@media (max-width: 767.98px) {
  .table-container p.price {
    font-size: 18px;
    line-height: 1.3;
  }
}

.table-container p.price span {
  color: var(--brand-gray1);
  font-size: 70%;
  position: relative;
  top: -8px;
}

.table-container .btn-secondary {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-column-gap: 16px;
          column-gap: 16px;
}

.table-container .btn-secondary img {
  width: 22px;
}

.form-container {
  max-width: 800px;
  margin: auto;
  padding: 32px 36px;
  border-radius: 10px;
  background-color: var(--brand-white);
}

.form-container h2 {
  text-align: center;
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 20px;
}

.btn-primary {
  margin-top: 20px;
  width: 100%;
}

.btn-secondary {
  width: 100%;
}

.btn-primary {
  background: var(--brand-red);
  border-color: var(--brand-red);
  color: var(--brand-white);
  font-size: 24px;
  line-height: 28px;
  padding-top: 20px;
  padding-bottom: 20px;
  border-radius: 0px;
}

.btn-primary:hover, .btn-primary:active, .btn-primary:focus {
  background: var(--brand-red);
  border-color: var(--brand-red);
  -webkit-box-shadow: none;
          box-shadow: none;
  outline: none;
}

.btn-primary:hover:focus, .btn-primary:active:focus, .btn-primary:focus:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
}

.btn-secondary {
  background: var(--brand-green1);
  border-color: var(--brand-green1);
  color: var(--brand-white);
  font-size: 24px;
  line-height: 28px;
  padding-top: 20px;
  padding-bottom: 20px;
  border-radius: 0px;
}

.btn-secondary:hover, .btn-secondary:active, .btn-secondary:focus {
  background: var(--brand-green1);
  border-color: var(--brand-green1);
  -webkit-box-shadow: none;
          box-shadow: none;
  outline: none;
}

.btn-secondary:hover:focus, .btn-secondary:active:focus, .btn-secondary:focus:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
}

.btn-secondary.btn-border {
  color: var(--brand-green1);
  background: none;
}

.error-message {
  color: red;
  font-size: 14px;
  margin-top: 5px;
}

.overlay-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.overlay-content {
  text-align: center;
  color: #ffffff;
}

.custom-form .form-label {
  font-size: 22px;
  line-height: 27px;
  color: var(--brand-black);
  font-weight: 500;
}

.custom-form .mb-20 {
  margin-bottom: 20px !important;
}

.custom-form .form-select, .custom-form .form-control {
  height: 68px;
  padding: 20px 26px;
  border: solid 1px var(--brand-gray);
  font-size: 22px;
  line-height: 27px;
  border-radius: 10px;
}

.custom-form .form-select:focus, .custom-form .form-control:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
}
