@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;700&display=swap');
@import url('https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css');

:root {
    --brand-white: #ffffff;
    --brand-light-bg: #F4F6FC;
    --brand-black: #000000;
    --brand-gray: #B5B5B5;
    --brand-gray1: #BCBCBC;
    --brand-red: #BC1823;
    --brand-red1: #DB2C37;
    --brand-green1: #157E38;
}

html,
body {
    font-family: "Rubik", Arial, Helvetica, sans-serif;
}

html body {
    background: var(--brand-light-bg);
}

*{
    box-sizing: border-box;
}
.mb-40{
    margin-bottom: 40px !important;
}
.mb-60{
    margin-bottom: 60px !important;
}


.header-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 44px;
    @media(max-width:991.98px){
        margin-bottom: 24px;
    }
    .header-logo-1 {
        @media(max-width:767.98px){
            display: none;
        }
        img{
            @media(max-width:991.98px){
                width:70px;
            }
            
        }
    }

    .header-text {
        color: var(--brand-black);
        font-size: 41px;
        font-weight: 700;
        line-height: 1.17;
        text-align: center;
        @media(max-width:991.98px){
            font-size:32px;
        }
        @media(max-width:767.98px){
            font-size:28px;
            flex:1 0 auto;
        }
    }

    .header-logo-2 {
        display: flex;
        align-items: center;
        column-gap: 20px;
        @media(max-width:767.98px){
            display: none;
        }
        img{
            @media(max-width:991.98px){
                width:70px;
            }
        }
    }
}


.table-container{
    max-width: 800px;
    margin: auto;
    padding: 32px 0px 0;
    &.table-data-sm{
        padding:0;
        h3{
            font-size:24px;
            line-height: 29px;
            text-align: center;
        }
        p{
            font-size:18px;
            line-height: 22px;
            &.price{
                font-size:20px;
                line-height: 24px;
                span{
                    top:-6px;
                }
            }
        }
        .brick-rates{
            display: block;
        }
        .brick-sizes{
            display: flex;
            justify-content: space-between;

        }
        .brick-type{
            background: var(--brand-white);
            border-radius: 10px;
            padding-left:34px;
            padding-right:34px;
        }
        .brick-size{
            background:none;
            margin-bottom: 0;
            border-radius: 0px;
        }
    }
    h3{
        font-size: 20px;
        line-height: 24px;
        font-weight: 700;
        text-transform: uppercase;
        margin: 0 0 18px;
        &.with-height{
            @media(max-width:767.98px){
                min-height: 50px;
            }
        }
    }
    .brick-rates {
        display: flex;
        justify-content: space-between;
    }
    
    .brick-type {
        padding: 0px;
        text-align: center;
        border-radius: 10px;
        flex: 1;
        &:first-child{
            .brick-sizes{
                .brick-size{
                    border-top-left-radius: 10px;
                    border-bottom-left-radius: 10px;
                }
            }
        }
        &:last-child{
            .brick-sizes{
                .brick-size{
                    border-top-right-radius: 10px;
                    border-bottom-right-radius: 10px;
                }
            }
        }
    }
    
    .brick-sizes {
        margin-top: 10px;
    }
    .brick-size {
        margin-bottom: 10px;
        background:var(--brand-white);
        padding:17px 0 9px 0;
        &.alternative{
            min-height: 132px;
            padding-top:51px;
            padding-bottom: 0px;
            @media(max-width:767.98px){
                padding-top:46px;
            }
            .interlock{
                display: flex;
                align-items: center;
                flex-direction: column;
                
                p{
                    font-size:24px;
                    line-height: 36px;
                    margin-bottom:0px;
                    @media(max-width:767.98px){
                        font-size:16px;
                        line-height: 23.4px;
                    }
                }
            }
            @media(max-width:767.98px){
                min-height: 102px;
            }
        }
    }
    p{
        font-size:20px;
        line-height: 1.2;
        color:var(--brand-black);
        text-transform: uppercase;
        margin: 0 0 10px;
        @media(max-width:767.98px){
            font-size:16px;
        }
        &.price{
            color:var(--brand-red1);
            font-size:30px;
            line-height: 1.2;
            margin-bottom: 0;
            @media(max-width:767.98px){
                font-size:18px;
                line-height: 1.3;
            }
            span{
                color:var(--brand-gray1);
                font-size:70%;
                position: relative;
                top:-8px;
            }
        }
    }
    .btn-secondary{
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 16px;
        img{
            width:22px;
        }
        
    }
    
}


.form-container {
    max-width: 800px;
    margin: auto;
    padding: 32px 36px;
    border-radius: 10px;
    background-color: var(--brand-white);
    h2 {
        text-align: center;
        margin-bottom: 20px;
    }
}



.form-group {
    margin-bottom: 20px;
}

.btn-primary {
    margin-top: 20px;
    width: 100%;
}
.btn-secondary {
    width: 100%;
}
.btn-primary{
    background:var(--brand-red);
    border-color:var(--brand-red);
    color:var(--brand-white);
    font-size:24px;
    line-height: 28px;
    padding-top:20px;
    padding-bottom: 20px;
    border-radius: 0px;
    &:hover, &:active, &:focus{
        background:var(--brand-red);
        border-color:var(--brand-red);
        box-shadow: none;
        outline: none;
        &:focus{
            box-shadow: none;
        }
    }
   
}

.btn-secondary{
    background:var(--brand-green1);
    border-color:var(--brand-green1);
    color:var(--brand-white);
    font-size:24px;
    line-height: 28px;
    padding-top:20px;
    padding-bottom: 20px;
    border-radius: 0px;
    &:hover, &:active, &:focus{
        background:var(--brand-green1);
        border-color:var(--brand-green1);
        box-shadow: none;
        outline: none;
        &:focus{
            box-shadow: none;
        }
    }
    &.btn-border{
        color:var(--brand-green1);
        background:none
    }
}

.error-message {
    color: red;
    font-size: 14px;
    margin-top: 5px;
}

.overlay-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.overlay-content {
    text-align: center;
    color: #ffffff;
}



.custom-form{
    .form-label{
        font-size:22px;
        line-height: 27px;
        color:var(--brand-black);
        font-weight: 500;
    }
    .mb-20{
        margin-bottom: 20px !important;
    }
    .form-select, .form-control{
        height:68px;
        padding:20px 26px;
        border:solid 1px var(--brand-gray);
        font-size:22px;
        line-height: 27px;
        border-radius: 10px;
        &:focus{
            box-shadow: none;
        }
    }
    
}